const resources = {
  en: {
    translation: {
      Search: 'Search',
      Pages: 'Pages',
      'General Ledger': 'General Ledger',
      'Journal Entries': 'Journal Entries',
      'Cash Transaction': 'Cash Transaction',
      'Bank Transaction': 'Bank Transaction',
      'Transfer Between Accounts': 'Transfer Between Accounts',
      'Foreign Exchange': 'Foreign Exchange',
      'Credit Limit': 'Credit Limit',
      'Account Title': 'Account Title',
      'Account Registration': 'Account Registration',
      Administrator: 'Administrator',
      'Sample Person': 'Moein Rabti',
      Drafts: 'Drafts',
      Title: 'Title',
      English: 'English',
      Alerts: 'Alerts',
      Alert: 'Alert',
      Persian: 'Persian',
      Account: 'Account',
      Documents: 'Documents',
      Reports: 'Reports',
      Settings: 'Settings',
      'Create Customer': 'Create Customer',
      And: 'And',
      Dashboard: 'Dashboard',
      Welcome: 'Welcome',
      'Moneyx Accounting Panel': 'Moneyx Accounting Panel',
      'Moneyx Exchange Accounting Software':
        'Moneyx Exchange Accounting Software',
      'Site under construction or maintenance':
        'Site under construction or maintenance',
      'building not finished yet': 'building not finished yet',
      'First Name': 'First Name',
      'Middle Name': 'Middle Name',
      'Last Name': 'Last Name',
      Gender: 'Gender',
      Undefined: 'Undefined',
      Unknown: 'Unknown',
      Male: 'Male',
      Female: 'Female',
      'Phone Number': 'Phone Number',
      'Language Selection': 'Language Selection',
      'Transfer Owners': 'Transfer Owners',
      Description: 'Description',
      Return: 'Return',
      'Register New Customer': 'Register New Customer',
      'Basic Information': 'Basic Information',
      Colon: ':',
      'Account Number': 'Account Number',
      'Add Account': 'Add Account',
      'Occupation And Personal Details': 'Occupation And Personal Details',
      'Add Address': 'Add Address',
      'Bank Information': 'Bank Information',
      Attachment: 'Attachments',
      'Birth Place Country': 'Birth Place Country',
      'Live Place Country': 'Live place country',
      Nationality: 'Nationality',
      'Birth Place': 'Birth Place',
      'Birth Date': 'Birth Date',
      Email: 'Email',
      Website: 'Website',
      Occupation: 'Occupation',
      'Other Occupation': 'Other Occupation',
      'Occupation Branch': 'Occupation Branch',
      'Economic Registering Number': 'Economic Registering Number',
      Continue: 'Continue',
      Iran: 'Iran',
      England: 'England',
      Iranian: 'Iranian',
      Programmer: 'Programmer',
      Employee: 'Employee',
      Country: 'Country',
      Province: 'Province',
      City: 'City',
      'Zip Code': 'Zip Code',
      'Exact Address': 'Exact Address',
      Tehran: 'Tehran',
      London: 'London',
      'Currency Type': 'Currency',
      'Account Owner Name': 'Account Owner Name',
      'Branch Code': 'Branch Code',
      'Swift Code': 'Swift Code',
      IBAN: 'IBAN',
      Register: 'Register',
      Rial: 'Rial',
      Pond: 'Pond',
      'Document Type': 'Document Type',
      'Document Number': 'Document Number',
      'Expire Date': 'Expire Date',
      'Issue Date': 'Issue Date',
      'Document Status': 'Document Status',
      Permanent: 'Permanent',
      Current: 'Current',
      'Type 1': 'Type 1',
      'Type 2': 'Type 2',
      'Drop Your Desired File Here': 'Drop Your Desired File Here',
      Or: 'Or',
      Access: 'Access',
      Choose: 'Choose',
      'Valid Formats': 'Valid Formats',
      Application: 'Application',
      'Application Settings': 'Application Settings',
      'Edit Number': 'Edit Number',
      'Connected Account': 'Connected Account',
      'Block Access To Application': 'Block Access To Application',
      'Send Notification Via': 'Send Notification Via',
      SMS: 'SMS',
      Telegram: 'Telegram',
      Whatsapp: 'WhatsApp',
      'Accept And Register': 'Accept And Register',
      'Accept And Continue': 'Accept And Continue',
      Customers: 'Customers',
      Customer: 'Customer',
      List: 'List',
      'Customers List': 'Customers List',
      'Register Customer': 'Register Customer',
      None: 'None',
      'Select Customer': 'Select Customer',
      'Sign in to your account to continue':
        'Sign in to your account to continue',
      'Welcome back': 'Welcome back',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Sign in': 'Sign in',
      'Remember me': 'Remember me',
      'Forgot password': 'Forgot password',
      Moneyx: 'Moneyx',
      'Exchange Accounting Software': 'Exchange Accounting Software',
      Balance: 'Balance',
      'Sample Balance': '5,435,000',
      'Sample Balance Currency': 'Tomans',
      'Complete Customer Information': 'Complete Customer Information',
      'Next Step': 'Next',
      Sent: 'Sent',
      'Not Sent': 'Not Sent',
      'Customer Information': 'Customer Information',
      All: 'All',
      Show: 'Show',
      Currencies: 'Currencies',
      'Draft Submission': 'Draft Submission',
      Branch: 'Branch',
      Sender: 'Sender',
      Receiver: 'Receiver',
      'Draft Currency Type': 'Draft Currency',
      'Company Registration': 'Company Registration',
      'Contact Details': 'Contact Details',
      'Address Information': 'Address Information',
      Address: 'Address',
      Addresses: 'Addresses',
      Shareholders: 'Shareholders',
      'Receivers List': 'Receivers List',
      'Receiver Registration': 'Receiver Registration',
      Accept: 'Accept',
      'Customer Registration': 'Customer Registration',
      'Search By': 'Search by',
      Name: 'Name',
      'User Name': 'User Name',
      Comma: ',',
      'Document Prime': 'Document',
      'Advanced Search': 'Advanced Search',
      'Customer Number': 'Customer Number',
      'Customer Name': 'Customer Name',
      Group: 'Group',
      Groups: 'Groups',
      'Father Name': 'Father Name',
      'Account History': 'Account History',
      'Applications Settings': 'Application Settings',
      'Draft Receivers': 'Draft Receivers',
      'Send Message': 'Send Message',
      'Customer Full Details': "Customer's Full Details",
      'Download Customers List Summary': 'Download Customers List Summary',
      'Download Customers Full List': 'Download Customers Full List',
      'Document Expiry Date': 'Document Expiry Date',
      To: 'To',
      Select: 'Select',
      Collateral: 'Collateral‌',
      'Communication Way': 'Communication Way',
      Enable: 'Enable',
      Webcam: 'Webcam',
      Upload: 'Upload',
      Photo: 'Photo',
      'Upload Photo': 'Upload Photo',
      'Add New Phone Number': 'Add New Phone Number',
      'Add New Employee': 'Add New Employee',
      'Employee Registration': 'Employee Registration',
      'At Least One Number Is Mandatory': 'At Least One Number Is Mandatory',
      'Register Customer Without Additional Information': 'Register Customer',
      'Wholesale Exchange': 'Wholesale Exchange',
      'Retail Exchange': 'Retail Exchange',
      Create: 'Create',
      'System Management': 'System Management',
      Business: 'Business',
      'Create Group': 'Create Group',
      Debtor: 'Debtor',
      Creditor: 'Creditor',
      'Create Currency': 'Create Currency',
      Normal: 'Normal',
      Other: 'Other',
      'United States Dollar': 'United States Dollar',
      Dollar: 'Dollar',
      'Account Remaining': 'Account Remaining',
      Remaining: 'Remaining',
      From: 'From',
      Date: 'Date',
      'Balance Currency Type': 'Currency',
      'Mobile Number': 'Phone Number',
      'Account Details': 'Account Details',
      'Banks List': 'Banks list',
      'Banks Management': 'Banks Management',
      Row: 'Row',
      Add: 'Add',
      Old: 'Old',
      New: 'New',
      'New Bank': 'New Bank',
      'Bank Name': 'Bank Name',
      'Card Number': 'Card Number',
      'Bank Branch Code': 'Bank Branch Code',
      Status: 'Status',
      Active: 'Active',
      Archived: 'Archived',
      'Bank Book': 'Bank Book',
      'No Bank Is Registered': 'No Bank Is Registered',
      Bank: 'Bank',
      Edit: 'Edit',
      'Submit Edit': 'Edit',
      'Archiving The Account': 'Archiving The Account',
      'Cash withdrawal': 'Cash withdrawal',
      'Cash deposit': 'Cash deposit',
      'Search In Moneyx': 'Search In Moneyx',
      'Quick Access': 'Quick Access',
      'Print Information': 'Print',
      'Upload File': 'Upload File',
      'Transaction Description': 'Transaction Description',
      'Receipt Number': 'Receipt Number',
      'Transaction Amount': 'Amount',
      Messages: 'Messages',
      Notifications: 'Notifications',
      Languages: 'Languages',
      'Account Balance': 'Account Balance',
      'Cash Desk': 'Cash Desk',
      'System Accounts': 'System Accounts',
      Cash: 'Cash',
      Costs: 'Costs',
      Commission: 'Commission',
      'Account Information': 'Account Information',
      Accounts: 'Accounts',
      ChargingSale: 'Charging Sale',
      UnrealizedDraft: 'Unrealized Draft',
      BankPayment: 'Bank Payment',
      'No Option Message': 'No Options',
      'Branch Registration': 'Branch Registration',
      'Advanced Details': 'Advanced Details',
      'Drafts List': 'Drafts List',
      'Branches List': 'Branches List',
      'Trade Currency': 'Trade Currency',
      'Currencies Management': 'Currencies Management',
      'Branch Account': 'Branch Account',
      'Unknown Bank Deposits': 'Unknown Deposit',
      'Submit Cash Transaction': 'Submit Cash Transaction',
      'Submit Bank Transaction': 'Submit Bank Transaction',
      Afghani: 'Afghani',
      Euro: 'Euro',
      'Register Person': 'Register Person',
      CommonAccount: 'CommonAccount',
      BranchAccount: 'BranchAccount',
      BankAccount: 'BankAccount',
      'Unknown Bank Transactions': 'Unknown Bank Transactions',
      customer: 'Customer',
      receiver: 'Receiver',
      Riyal: 'Riyal',
      Dirham: 'Dirham',
      Rupee: 'Rupee',
      Toman: 'Toman',
      Ruble: 'Ruble',
      'Sample Page': 'Sample Page',
      'Continue without registering an address':
        'Continue without registering an address',
      'New password must be repeated.': 'New password must be repeated.',
      'This field is required.': 'This field is required.',
      'The password must be including one capital letter':
        'The password must be including one capital letter',
      'The password must be including one number':
        'The password must be including one number',
      'The password must be including one symbol':
        'The password must be including one symbol',
      'The password must be at least 4 characters and at most 8 characters':
        'The password must be at least 4 characters and at most 8 characters',
      'The entered email is not correct': 'The entered email is not correct',
      'The new password should not be the same as the password current':
        'The new password should not be the same as the password current',
      'The confirm password must be the same as new password':
        'The confirm password must be the same as new password',
      'The entered phon number is not correct':
        'The entered phon number is not correct',
      Weak: 'Weak',
      Medium: 'Medium',
      Good: 'Good',
      Excellent: 'Excellent',
      'Account Transfer': 'Account Transfer',
      'Record And Edit': 'Record And Edit',
      Delete: 'Delete',
      'Account For Person': 'Account For Person',
      'System Account': 'System Account',
      Record: 'Record',
      cheque: 'cheque',
      'Record And Edit Check Operations': 'Record And Edit Check Operations',
      'The Person': 'The Person',
      'Display Customer List': 'Display Customer List',
      'Failure To Display The Complete Person Along With Access':
        'Failure To Display The Complete Person Along With Access',
      Company: 'Company',
      'Display List Of Recipients': 'Display List Of Recipients',
      'Choosing A Receiver For The Customer':
        'Choosing A Receiver For The Customer',
      Representation: 'Representation',
      System: 'System',
      'Show Representative List': 'Show Representative List',
      'The Remittance Came': 'The Remittance Came',
      'The Remittance Went': 'The Remittance Went',
      'List Of General Documents': 'List Of General Documents',
      'Show The General List Of Remittances':
        'Show The General List Of Remittances',
      'Interbank Transaction': 'Interbank Transaction',
      'Bank Office': 'Bank Office',
      'Upload Excel File': 'Upload Excel File',
      'System Settings': 'System Settings',
      'User account panel settings': 'User account panel settings',
      'Type Of Calculations': 'Type Of Calculations',
      'User Management': 'User Management',
      Update: 'Update',
      'Add And Remove': 'Add And Remove',
      'Access To The Accounting Department':
        'Access To The Accounting Department',
      'Access To Drafts': 'Access to drafts',
      'Access To System Settings': 'Access to system settings',
      'Identity Section Access': 'Identity Section Access',
      'Access To The Representative Department':
        'Access To The Representative Department',
      'Bank Office Access': 'Bank Office Access',
      'Register And Continue': 'Register And Continue',
      'Confirm And Continue': 'Confirm And Continue',
      'User Panel': 'User Panel',
      'old password is incorrect': 'Current Password Is Incorrect',
      'password same as old password': 'Password is same as old password',
      'List Of Reports': 'List Of Reports',
      'List Of Payment Locations': 'List Of Payment Locations',
      'List Of Accounts': 'List Of Accounts',
      'Customer List': 'Customer List',
      'List Of Rates': 'List Of Rates',
      'Add Rates': 'Add Rates',
      'Add Payment Location': 'Add Payment Location',
      'Edit Payment Location': 'Edit Payment Location',
      'Customer Id': 'Customer Id',
      'Date Of Registration': 'Date Of Registration',
      'Up To Date': 'Up To Date',
      'Name Of Account Holder': 'Name Of Account Holder',
      'Search By Name, Mobile Number Or Username...':
        'Search By Name, Mobile Number Or Username...',
      'Mobile Number Search...': 'Mobile Number Search...',
      Validity: 'Validity',
      Profit: 'Profit',
      'Exchanger Paid Currency': 'Exchanger Paid Currency',
      'Exchanger Paid Amount': 'Exchanger Paid Amount',
      'Customer Paid Currency': 'Customer Paid Currency',
      'Customer Paid Amount': 'Customer Paid Amount',
      'Filter By': 'Filter By',
      'Place Of Payment': 'Place Of Payment',
      'Add New Number': 'Add New Number',
      'Disable Charging': 'Disable Charging',
      'Temporarily Disable The User': 'Temporarily Disable The User',
      'Expulsion From The Program': 'Expulsion From The Program',
      'Reactivation Of Recharge Purchases':
        'Reactivation Of Recharge Purchases',
      'User Reactivation': 'User Reactivation',
      'Back To The Program': 'Back To The Program',
      'Add Rate': 'Add Rate',
      'Edit Rate': 'Edit Rate',
      'Add place of payment': 'Add place of payment',
      'Number Phon': 'Number Phon',
      'First Value': 'First Value',
      'Second Value': 'Second Value',
      currency: 'currency',
      'Add Ticket': 'Add ticket',
      'Currency Name': 'Currency Name',
      'Are You Sure You Want To Disable User Charge Purchases?':
        'Are You Sure You Want To Disable User Charge Purchases?',
      'Are You Sure To Delete The Place Of Payment?':
        'Are You Sure To Delete The Place Of Payment?',
      'Are you sure to delete the rate?': 'Are you sure to delete the rate?',
      'Are You Sure You Want To Expulsion From The Program?':
        'Are You Sure You Want To Expulsion From The Program?',
      'Are You Sure You Want To Disable Temporary User?':
        'Are You Sure You Want To Disable Temporary User?',
      'Are You Sure To Delete The Employee?':
        'Are You Sure To Delete The Employee?',
      'Sign Out': 'Sign Out',
      Yearly: 'yearly',
      Monthly: 'Monthly',
      Online: 'Online',
      crypto: 'crypto',
      cash: 'cash',
      Salary: 'Salary',
      Share: 'Share',
      Subset: 'Subset',
      'Organization Chart': 'Organization chart',
      // ************************ transaction description translations **************************
      Profile: 'Profile',
      'View Profile': 'View Profile',
      'Account Panel': 'Account Panel',
      'Employees List': 'Employees List',
      'Personal Information': 'Personal Information',
      'Change Password': 'Change Password',
      'Last Visit': 'Last Visit',
      Condition: 'Condition',
      Cancel: 'Cancel',
      'The Operation': 'The Operation',
      Operation: 'Operation',
      Inactive: 'Inactive',
      'System Access': 'System Access',
      'View User Profile': 'View User Profile',
      'Employee Profile': 'Employee Profile',
      'Birth Country': 'Birth Country',
      'Date Of Birth': 'Date Of Birth',
      'Repeat The Password': 'Repeat The Password',
      'Confirm password': 'Confirm password',
      'Exact Location': 'Exact Location',
      State: 'State',
      'Postal Code': 'Postal Code',
      'User Access': 'User Access',
      'User permissions': 'User permissions',
      'Newspaper Office': 'Newspaper Office',
      'Cash Transaction Registration': 'Cash Transaction Registration',
      'Bank Transaction Registration': 'Bank Transaction Registration',
      'Currency Management': 'Currency Management',
      'Unknown Banking Transactions': 'Unknown Banking Transactions',
      'General Office': 'General Office',
      Remittances: 'Remittances',
      'Access To The Whole System': 'Access To The Whole System',
      'Access System': 'Access System',
      'System Version': 'System Version',
      'Buy Version': 'Buy Version',
      'Subscription period': 'Subscription period',
      'remaining time': 'remaining time',
      'Subscription status': 'Subscription status',
      'Register for confirmation': 'Register for confirmation',
      'user has this package': 'User Has This Package',
      'Profit And Loss': 'Profit And Loss',
      'Cancel the reservation': 'Cancel the reservation',
      'I realized': 'I realized',
      'Login to the accounting system': 'Login to the accounting system',
      'The new password must be repeated.':
        'The new password must be repeated.',
      'The new password should not be the same as the current password':
        'The new password should not be the same as the current password',
      // ************************ transaction description translations **************************
      // ************************ transaction description translations **************************
      'Commission for': 'Commission for',
      'transfer between accounts': 'transfer between accounts',
      'transaction id': 'transaction id',
      'transaction id:': 'transaction id:',
      'transfer between accounts with': 'transfer between accounts with',
      amount: 'amount',
      debtor: 'debtor',
      creditor: 'creditor',
      'Outgoing draft order': 'Outgoing draft order',
      'Pending Draft List': 'Pending Draft List',
      'Show Pending Draft List': 'Show Pending Draft List',
      'Incoming draft order': 'Incoming draft order',
      'Miscellaneous draft order': 'Miscellaneous draft order',
      to: 'to',
      from: 'from',
      at: 'at',
      'was registered.': 'was registered.',
      'commission for': 'commission for',
      'bank account': 'bank account',
      'customer account': 'customer account',
      'Customer Amount': 'Customer Amount',
      'Amount currency': 'Amount currency',
      'sell currency': 'sell currency',
      'buy currency': 'buy currency',
      'bank transaction': 'bank transaction',
      commission: 'commission',
      // ************************ end transaction description translations **************************
      Rates: 'Rates',
      Reporting: 'Reporting',
      'Charge Management Panel': 'Charge Management Panel',
      PoliticalFighter: 'Political fighter',
      HighRisk: 'Hight risk',
      ModerateRisk: 'Moderate risk',
      LowRisk: 'Low risk',
      SmallBusiness: 'Small business',
      BigBusiness: 'Big business',
      CurrencyAuction: 'Currency auction',
      SanctionList: 'Sanction list',
      'Central analysis of trades and financial reports':
        'Central analysis of trades and financial reports',
      'Exchange license validation': 'Exchange license validation',
      Accesses: 'Access',
      TransactionDate: 'Transaction date',
      Transactions: 'Transactions',
      Position: 'Position',
      UserAccountSystem: 'User account system',
      AccountingSystem: 'Accounting system',
      'Departments List': 'Departments List',
      'Add New Departments': 'Add new departments',
      'No Content Message': 'No content',
      ExitFromSystem: 'Exit from system'
    }
  },
  fa: {
    translation: {
      dir: 'rtl',
      Search: 'جستجو',
      Pages: 'صفحات',
      Alerts: 'اطلاع رسانی',
      Alert: 'اعلان',
      'General Ledger': 'دفتر کل',
      'Journal Entries': 'دفتر روزنامه',
      'Cash Transaction': 'تراکنش نقدی',
      'Bank Transaction': 'تراکنش بانکی',
      'Transfer Between Accounts': 'انتقال بین حساب',
      'Foreign Exchange': 'خرید و فروش ارز',
      'Sample Person': 'معین ربطی',
      'Credit Limit': 'سقف اعتبار',
      'Account Title': 'عنوان حساب',
      'Account Registration': 'افتتاح حساب',
      'Create Customer': 'ثبت مشتری جدید',
      'Site under construction or maintenance': 'در حال تولید هستیم ...',
      'building not finished yet': 'محتوای این قسمت هنوز آماده نیست :)',
      And: 'و',
      English: 'انگلیسی',
      Persian: 'فارسی',
      Title: 'عنوان',
      Account: 'حساب',
      Drafts: 'حواله ها',
      Documents: 'مدارک',
      Reports: 'گزارشات',
      Settings: 'تنظیمات',
      Administrator: 'مدیر سیستم',
      Dashboard: 'داشبورد',
      Welcome: 'خوش آمدید',
      'Moneyx Accounting Panel': 'پنل حسابداری مانیکس',
      'Moneyx Exchange Accounting Software': 'نرم افزار حسابداری مانیکس ',
      'First Name': 'نام',
      'Middle Name': 'نام میانی',
      'Last Name': 'نام خانوادگی',
      Gender: 'جنسیت',
      Undefined: 'نامعلوم',
      Unknown: 'نامعلوم',
      Male: 'مرد',
      Female: 'زن',
      'Phone Number': 'شماره تماس',
      'Language Selection': 'انتخاب زبان',
      'Transfer Owners': 'حواله داران', // comment required
      Description: 'توضیحات',
      Return: 'بازگشت',
      'Register New Customer': 'ثبت مشتری جدید',
      'Basic Information': 'اطلاعات اولیه',
      Colon: ':',
      'Account Number': 'شماره حساب',
      'Add Account': 'افزودن حساب',
      'Edit Account': 'ویرایش حساب',
      'Occupation And Personal Details': 'اطلاعات فردی و شغلی',
      'Add Address': 'افزودن آدرس',
      'Bank Information': 'اطلاعات بانک',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'کشور محل تولد',
      'Live Place Country': 'کشور محل زندگی',
      Nationality: 'ملیت',
      'Birth Place': 'محل تولد',
      'Birth Date': 'تاریخ تولد',
      Email: 'ایمیل',
      Website: 'وب سایت',
      Occupation: 'شغل',
      'Other Occupation': 'عنوان شغل (در صورت انتخاب سایر)',
      'Occupation Branch': 'نوع کار',
      'Economic Registering Number': 'کد اقتصادی',
      Continue: 'ادامه',
      England: 'انگلیس',
      Iranian: 'ایرانی',
      Programmer: 'برنامه نویس',
      Employee: 'کارمند',
      Country: 'کشور',
      Province: 'استان',
      City: 'شهر',
      'Zip Code': 'کد پستی',
      'Exact Address': 'نشانی دقیق',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'نوع ارز',
      'Account Owner Name': 'نام صاحب حساب',
      'Branch Code': 'کد شعبه',
      'Swift Code': 'کد سوئیفت',
      IBAN: 'شماره شبا',
      Register: 'ثبت',
      Rial: 'ریال',
      Pond: 'پوند',
      'Document Type': 'نوع مدرک',
      'Document Number': 'شماره مدرک',
      'Expire Date': 'تاریخ انقضا',
      'Issue Date': 'تاریخ صدور',
      'Document Status': 'وضعیت مدرک',
      Permanent: 'دائمی',
      Current: 'فعلی',
      'Type 1': 'نوع اول',
      'Type 2': 'نوع دوم',
      'Drop Your Desired File Here': 'فایل مورد نظر را اینجا رها کنید',
      Or: 'یا',
      Access: 'دسترسی',
      Choose: 'انتخاب کنید',
      'Valid Formats': 'فرمت‌های مجاز',
      Application: 'اپلیکیشن',
      'Application Settings': 'تنظیمات اپلیکیشن',
      'Edit Number': 'ویرایش شماره',
      'Connected Account': 'حساب متصل',
      'Block Access To Application': 'بلاک کردن دسترسی به اپلیکیشن',
      'Send Notification Via': 'ارسال نوتیفیکیشن از طریق',
      SMS: 'پیامک',
      Telegram: 'تلگرام',
      Whatsapp: 'واتساپ',
      'Accept And Register': 'تایید و ثبت',
      'Accept And Continue': 'تایید و ادامه',
      Customers: 'مشتریان',
      'Customers List': 'لیست مشتریان',
      Customer: 'مشتری',
      List: 'لیست',
      'Register Customer': 'ثبت مشتری',
      'Select Customer': 'انتخاب مشتری',
      'Sign in to your account to continue': 'بر ادامه لطفاً وارد شوید.',
      'Welcome back': 'خوش آمدید',
      None: 'نامشخص',
      System: 'سامانه',
      'Email Address': 'آدرس ایمیل',
      Password: 'رمز عبور',
      'Sign in': 'ورود',
      'Remember me': 'مرا به خاطر بسپار',
      'Forgot password': 'پسوردم فراموش شده!',
      Use: 'استفاده کنید',
      'to sign in': 'برای ورود.',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'نرم افزار حسابداری صرافی',
      Balance: 'موجودی',
      'Sample Balance': '۵/۴۳۵/۰۰۰',
      'Sample Balance Currency': 'تومان',
      'Complete Customer Information': 'تکمیل اطلاعات مشتری',
      'Next Step': 'مرحله بعدی',
      Sent: 'ارسال شده',
      'Not Sent': 'ارسال نشده',
      'Customer Information': 'اطلاعات مشتری',
      All: 'همه',
      Show: 'نمایش',
      Currencies: 'ارزها',
      'Draft Submission': 'ثبت حواله',
      Branch: 'نمایندگی',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      'Draft Currency Type': 'نوع ارز حواله',
      'Company Registration': 'ثبت شرکت',
      'Contact Details': 'اطلاعات تماس',
      'Address Information': 'اطلاعات آدرس',
      Address: 'آدرس',
      Addresses: 'آدرس‌ها',
      Shareholders: 'سهام‌داران',
      'Receivers List': 'لیست گیرنده‌ها',
      'Receiver Registration': 'ثبت گیرنده',
      Accept: 'تایید',
      'Customer Registration': 'ثبت مشتری',
      'Search By': 'جستجو با',
      Name: 'نام',
      'User Name': 'نام کاربری',
      Comma: '،',
      'Document Prime': 'مدرک',
      'Advanced Search': 'جستجوی پیشرفته',
      'Customer Number': 'شماره مشتری',
      'Customer Name': 'نام مشتری',
      Group: 'گروه',
      Groups: 'گروه‌ها',
      'Father Name': 'نام پدر',
      'Account History': 'تاریخچه حساب',
      'Applications Settings': 'تنظیمات برنامه‌ها',
      'Draft Receivers': 'گیرنده‌های حواله',
      'Send Message': 'ارسال پیام',
      'Customer Full Details': 'اطلاعات کامل مشتری',
      'Download Customers List Summary': 'دانلود لیست با خلاصه مشتریان',
      'Download Customers Full List': 'دانلود لیست با مشخصات کامل',
      'Document Expiry Date': 'تاریخ انقضاء مدرک',
      To: 'تا',
      Select: 'انتخاب',
      Collateral: 'ضامن',
      'Communication Way': 'نوع تماس با شماره',
      Enable: 'فعالسازی',
      Webcam: 'وبکم',
      Upload: 'آپلود',
      Photo: 'عکس',
      'Upload Photo': 'آپلود عکس',
      'Add New Phone Number': 'افزودن شماره جدید',
      'Add New Employee': 'افزودن کارمند جدید',
      'Employee Registration': 'ثبت کارمند',
      'At Least One Number Is Mandatory': 'حداقل یک شماره اجباری است',
      'Register Customer Without Additional Information':
        'ثبت شخص بدون اطلاعات تکمیلی',
      'Wholesale Exchange': 'حواله‌دار عمده',
      'Retail Exchange': 'حواله‌دار خرده',
      Create: 'ایجاد',
      'System Management': 'مدیریت سامانه',
      Business: 'کسب و کار',
      'Create Group': 'ایجاد گروه',
      Debtor: 'بدهکار',
      Creditor: 'طلبکار',
      'Create Currency': 'ایجاد ارز',
      Normal: 'عادی',
      Other: 'سایر',
      'United States Dollar': 'دلار آمریکا',
      Dollar: 'دلار',
      'Account Remaining': 'باقیمانده حساب',
      Remaining: 'باقیمانده',
      From: 'از',
      Date: 'تاریخ',
      'Balance Currency Type': 'نوع ارز موجودی',
      'Mobile Number': 'شماره موبایل',
      'Account Details': 'اطلاعات حساب',
      'Banks List': 'لیست بانک‌ها',
      'Banks Management': 'مدیریت بانک‌ها',
      Row: 'ردیف',
      Add: 'افزودن',
      New: 'جدید',
      Old: 'قدیم',
      'New Bank': 'بانک جدید',
      'Bank Name': 'نام بانک',
      'Card Number': 'شماره کارت',
      'Bank Branch Code': 'کد شعبه',
      Status: 'وضعیت',
      Active: 'فعال',
      Archived: 'بایگانی',
      'Bank Book': 'دفتر بانک',
      'No Bank Is Registered': 'هیچ بانکی ثبت نشده است',
      Bank: 'بانک',
      Edit: 'ویرایش',
      'Submit Edit': 'ثبت ویرایش',
      'Archiving The Account': 'بایگانی کردن حساب',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      'Search In Moneyx': 'جستجو در مانیکس',
      'Quick Access': 'دسترسی سریع',
      'Print Information': 'چاپ اطلاعات',
      'Upload File': 'آپلود فایل',
      'Transaction Description': 'شرح تراکنش',
      'Receipt Number': 'شماره فیش',
      'Transaction Amount': 'مبلغ تراکنش',
      Messages: 'پیام‌ها',
      Notifications: 'اعلان‌ها',
      Languages: 'زبان‌ها',
      'Account Balance': 'موجودی حساب',
      'Cash Desk': 'صندوق',
      'System Accounts': 'حساب‌های سامانه',
      Cash: 'صندوق',
      Costs: 'هزینه‌ها',
      Commission: 'کارمزد',
      'Account Information': 'اطلاعات حساب',
      Accounts: 'حساب‌ها',
      ChargingSale: 'فروش شارژ',
      UnrealizedDraft: 'حواله‌های اجرا نشده',
      BankPayment: 'دریافت و پرداخت بانکی',
      'No Option Message': 'موردی وجود ندارد',
      'Branch Registration': 'ثبت نمایندگی',
      'Advanced Details': 'اطلاعات تکمیلی',
      'Drafts List': 'لیست حواله‌ها',
      'Branches List': 'لیست نمایندگی‌ها',
      'Trade Currency': 'خرید و فروش ارز',
      'Currencies Management': 'مدیریت ارزها',
      'Branch Account': 'حساب نمایندگی',
      'Unknown Bank Deposits': 'واریزهای نامعلوم بانکی',
      'Submit Cash Transaction': 'ثبت تراکنش نقدی',
      'Submit Bank Transaction': 'ثبت تراکنش بانکی',
      Afghani: 'افغانی',
      Euro: 'یورو',
      'Register Person': 'ثبت شخص',
      CommonAccount: 'حساب',
      BranchAccount: 'نمایندگی',
      BankAccount: 'بانک',
      'Unknown Bank Transactions': 'تراکنش‌های نامعلوم بانکی',
      customer: 'مشتری',
      receiver: 'گیرنده',
      Riyal: 'ریال',
      Dirham: 'درهم',
      Rupee: 'روپیه',
      Toman: 'تومان',
      Ruble: 'روبل',
      'Continue without registering an address': 'ادامه بدون ثبت آدرس',
      'New password must be repeated.':
        'رمز عبور جدید باید با تکرار آن برابر باشد.',
      'This field is required.': 'این فیلد الزامی می باشد.',
      'The password must be at least 4 characters and at most 8 characters':
        'رمز عبور باید حداقل 4 کاراکتر و حداکثر 8 کاراکتر باشد',
      'The password must be including one capital letter':
        'رمز عبور باید شامل یک حرف بزرگ باشد',
      'The password must be including one number':
        'رمز عبور باید شامل یک عدد باشد',
      'The password must be including one symbol':
        'رمز عبور باید شامل یک علامت باشد',
      'The entered email is not correct': 'ایمیل وارد شده صحیح نمی باشد',
      'The new password should not be the same as the password current':
        '.رمز عبور جدید نباید با رمز عبور قبلی برابر باشد',
      'The confirm password must be the same as new password':
        'تایید رمز عبور جدید باید با رمز عبور جدید برابر باشد',
      'The entered phon number is not correct':
        'شماره تلفن وارد شده صحیح نمی باشد',
      Weak: 'ضعیف',
      Medium: 'متوسط',
      Good: 'خوب',
      Excellent: 'عالی',
      'Add Ticket': 'ثبت تیکت',
      'Account Transfer': 'انتقال حساب',
      'Record And Edit': 'ثبت و ویرایش',
      Delete: 'حذف',
      'Account For Person': 'حساب برای شخص',
      'System Account': 'حساب سامانه',
      cheque: 'چک',
      'Record And Edit Check Operations': 'ثبت و ویرایش عملیات چک',
      'The Person': 'شخص',
      'Display Customer List': 'نمایش لیست مشتریان',
      'Failure To Display The Complete Person Along With Access':
        'عدم نمایش کامل شخص همراه با دسترسی',
      Company: 'شرکت',
      'Display List Of Recipients': 'نمایش لیست گیرندگان',
      'Choosing A Receiver For The Customer': 'انتخاب گیرنده برای مشتری',
      'Show Representative List': 'نمایش لیست نمایندگی',
      'The Remittance Came': 'حواله آمد',
      'The Remittance Went': 'حواله رفت',
      'List Of General Documents': 'لیست اسناد کلی',
      'Show The General List Of Remittances': 'نمایش لیست کلی حواله',
      'Interbank Transaction': 'تراکنش بین بانکی',
      'Bank Office': 'دفتر بانک',
      'Customer Account': 'حساب مشتری',
      'Upload Excel File': 'آپلود فایل اکسل',
      'System Settings': 'تنظیمات سامانه',
      'User account panel settings': 'تنظیمات پنل حساب کاربری',
      'Type Of Calculations': 'نوع محاسبات',
      'User Management': 'مدیریت کاربران',
      Update: 'بروزرسانی',
      'Add And Remove': 'اضافه و حذف',
      'Access To The Accounting Department': 'دسترسی بخش حسابداری',
      'Access To Drafts': 'دسترسی به حواله',
      'Access To System Settings': 'دسترسی به تنظیمات سامانه',
      'Identity Section Access': 'دسترسی بخش هویت',
      'Access To The Representative Department': 'دسترسی بخش نمایندگی',
      'Bank Office Access': 'دسترسی دفتر بانک',
      'Register And Continue': 'ثبت و ادامه',
      'Confirm And Continue': 'تایید و ادامه',
      'User Panel': 'پنل کاربری',
      'old password is incorrect': 'رمز عبور فعلی صحیح نمی باشد',
      'password same as old password': 'رمز عبور با رمز عبور فعلی برابر است',
      'List Of Reports': 'لیست گزارشات',
      'List Of Payment Locations': 'لیست محل های پرداخت',
      'List Of Accounts': 'لیست حساب ها',
      'Customer List': 'لیست مشتریان',
      'List Of Rates': 'لیست نرخ ها',
      'Add Rates': 'افزودن نرخ ها',
      'Add Payment Location': 'افزودن محل پرداخت',
      'Edit Payment Location': 'ویرایش محل پرداخت',
      'Customer Id': 'شناسه مشتری',
      'Date Of Registration': 'تاریخ ثبت نام',
      'Up To Date': 'تا تاریخ',
      'Name Of Account Holder': 'نام صاحب حساب',
      'Search By Name, Mobile Number Or Username...':
        'جستجوی با نام، شماره موبایل یا نام کاربری ...',
      'Mobile Number Search...': 'جستجوی شماره موبایل ...',
      Validity: 'اعتبار',
      Profit: 'سود',
      'Exchanger Paid Currency': 'ارز مبلغ حواله داری',
      'Exchanger Paid Amount': 'مبلغ حواله داری',
      'Customer Paid Currency': 'ارز مبلغ',
      'Customer Paid Amount': 'مبلغ مشتری',
      'Filter By': 'فیلتر بر اساس',
      'Place Of Payment': 'محل پرداخت',
      'Add New Number': 'افزودن شماره جدید',
      'Disable Charge Purchases': 'غیر فعال کردن شارژ',
      'Reactivation Of Charge Purchases': 'فعالسازی مجدد خرید شارژ',
      'Temporarily Disable The User': 'غیر فعال کردن موقت کاربر',
      'User Reactivation': 'فعالسازی مجدد کاربر',
      'Expulsion From The Program': 'اخراج از برنامه',
      'Back To The Program': 'بازگشت به برنامه',
      'Add Rate': 'افزودن نرخ',
      'Edit Rate': 'ویرایش نرخ',
      'Add place of payment': 'افزودن محل پرداخت',
      'From Date': 'از تاریخ',
      'Untile Date': 'تا تاریخ',
      'First Value': 'مقدار اول',
      'Second Value': 'مقدار دوم',
      currency: 'ارز',
      'Currency Name': 'نام ارز',
      'Are You Sure You Want To Disable User Charge Purchases?':
        'از غیرفعال کردن شارژ اطمینان دارید؟',
      'ARE YOU SURE TO DELETE THE ACCOUNT?': 'از حذف حساب اطمینان دارید؟',
      'Are You Sure To Delete The Place Of Payment?':
        'از حذف محل پرداخت اطمینان دارید؟',
      'Are you sure to delete the rate?': 'از حذف نرخ اطمینان دارید؟',
      'Are You Sure You Want To Disable Temporary User?':
        'از غیر فعال کردن موقت کاربر اطمینان دارید؟',
      'Are You Sure You Want To Expulsion From The Program?':
        'از اخراج از برنامه اطمینان دارید؟',
      'Are You Sure To Delete The Employee?': 'از حذف کارمند اطمینان دارید؟',
      'Sign Out': 'خروج',
      'Register for confirmation': 'ثبت جهت تایید',
      'Cancel the reservation': 'لغو رزرو',
      'I realized': 'متوجه شدم',
      'Login to the accounting system': 'ورود به سامانه حسابداری',
      'The new password must be repeated.':
        'تکرار رمز عبور با مقدار رمز باید برابر باشد.',
      'The new password should not be the same as the current password':
        'رمز عبور جدید نباید با رمز عبور فعلی یکی باشد',
      Yearly: 'سالانه',
      Monthly: 'ماهیانه',
      Online: 'اینترنتی',
      crypto: 'ارز دیجیتال',
      cash: 'نقدی',
      Salary: 'دستمزد',
      Share: 'سهم',
      Subset: 'زیرمجموعه',
      'Organization Chart': 'چارت تشکیلاتی',
      // ****************************************************************************************
      // countries
      // ****************************************************************************************
      Afghanistan: 'افغانستان',
      Australia: 'استرالیا',
      China: 'چین',
      Denmark: 'دانمارک',
      Germany: 'آلمان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'نروژ',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'عربستان سعودی',
      Sweden: 'سوئد',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'امارات متحده عربی',
      // ****************************************************************************************
      // end countries
      // ****************************************************************************************
      // ************************ transaction description translations **************************
      Profile: 'پروفایل',
      'View Profile': 'مشاهده پروفایل',
      'Account Panel': 'پنل حساب کاربری',
      'Employees List': 'لیست کارمندان',
      'Personal Information': 'اطلاعات فردی',
      'Change Password': 'تغییر رمز عبور',
      'Last Visit': 'آخرین بازدید',
      Condition: 'شرایط',
      Cancel: 'لغو',
      'The Operation': 'عملیات',
      Operation: 'عملیات',
      Inactive: 'غیرفعال',
      'System Access': 'دسترسی سامانه',
      'View User Profile': 'مشاهده پروفایل کاربر',
      'Employee Profile': 'پروفایل کارمند',
      'Birth Country': 'کشور تولد',
      'Date Of Birth': 'تاریخ تولد',
      'Repeat The Password': 'تکرار رمز عبور',
      'Confirm password': 'تکرار رمز عبور جدید',
      'Exact Location': 'آدرس دقیق',
      State: 'استان',
      'Postal Code': 'کد پستی',
      'User Access': 'دسترسی کاربر',
      'User permissions': 'دسترسی‌های کاربر',
      'Newspaper Office': 'اداره خبری',
      'Cash Transaction Registration': 'ثبت تراکنش نقدی',
      'Bank Transaction Registration': 'ثبت تراکنش بانکی',
      'Currency Management': 'مدیریت ارز',
      'General Office': 'اداره عمومی',
      Remittances: 'تحویل',
      'Access To The Whole System': 'دسترسی به  تمام سامانه',
      'Access System': 'دسترسی به سامانه',
      Record: 'ثبت',
      'Number Phon': 'شماره همراه',
      'System Version': 'نسخه سامانه',
      'Buy Version': 'خرید نسخه',
      Version: 'نسخه',
      'Subscription period': 'مدت زمان اشتراک',
      'remaining time': 'زمان باقی مانده',
      'Subscription status': 'وضعیت اشتراک',
      'user has this package': 'این اشتراک برای کاربر از قبل موجود می باشد.',
      'Profit And Loss': 'سود و زیان',
      'Day Operation': 'عملیات روز',
      // ************************ transaction description translations **************************
      // ************************ transaction description translations **************************
      'Commission for': 'کارمزد',
      'transfer between accounts': 'انتقال بین حساب',
      'transaction id': 'شماره تراکنش',
      'transaction id:': 'شماره تراکنش:',
      'transfer between accounts with': 'انتقال بین حساب با',
      amount: 'مبلغ',
      debtor: 'بدهکار',
      creditor: 'طلبکار',
      'Outgoing draft order': 'حواله رفت',
      'Incoming draft order': 'حواله آمد',
      'Pending Draft List': 'لیست حواله در حال انتظار',
      'Show Pending Draft List': ' نمایش لیست حواله در حال انتظار',
      'Miscellaneous draft order': 'حواله متفرقه',
      to: 'به',
      from: 'از',
      at: 'در',
      'was registered.': 'ثبت شد.',
      'commission for': 'کارمزد',
      'bank account': 'حساب بانک',
      'customer account': 'حساب مشتری',
      'sell currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'bank transaction': 'تراکنش بانکی',
      commission: 'کارمزد',
      // ************************ end transaction description translations **************************
      Rates: 'نرخ ها',
      Reporting: 'گزارش گیری',
      'Charge Management Panel': 'پنل مدیریت شارژ',
      PoliticalFighter: 'متبارز سیاسی',
      HighRisk: 'پرخطر',
      ModerateRisk: 'خطر متوسط',
      LowRisk: 'کم‌خطر',
      SmallBusiness: 'کسب و کار کوچک',
      BigBusiness: 'کسب و کار بزرگ',
      CurrencyAuction: 'لیلام اسعار',
      SanctionList: 'تطبیق لیست تعزیرات',
      'Central analysis of trades and financial reports':
        'مرکز تحلیل معاملات و راپورهای مالی',
      'Exchange license validation': 'اعتبارسنجی جواز',
      Accesses: 'دسترسی‌ها',
      TransactionDate: 'تاریخ تراکنش',
      Transactions: 'تراکنش‌ها',
      Position: 'موقعیت شغلی',
      UserAccountSystem: 'سامانه حساب کاربری',
      AccountingSystem: 'سامانه حسابداری',
      'Departments List': 'لیست شعب',
      'Add New Departments': 'افزودن شعبه جدید',
      'No Content Message': 'موردی وجود ندارد',
      ExitFromSystem: 'خروج از برنامه'
    },
    dir: 'rtl'
  },
  fad: {
    translation: {
      dir: 'rtl',
      'Display reports up to': ' نمایش گزارش تا ساعت:',
      Search: 'جستجو',
      Pages: 'صفحات',
      Alerts: 'هشدار ها',
      Alert: 'هشدار',
      Transaction: 'معامله',
      'Accounting Panel': 'صفحه حسابداری',
      Update: 'بروزرسانی',
      'Your Credit': 'اعتبار شما',
      'Add Ticket': 'ثبت تیکت',
      'General Ledger': 'دفتر کل',
      'Journal Entries': 'روزنامچه',
      'Cash Transaction': 'معاملات نقدی',
      'Bank Transaction': 'معاملات بانکی',
      'Transfer Between Accounts': 'انتقال بین حساب',
      'Foreign Exchange': 'تبادله اسعار',
      'Sample Person': 'معین ربطی',
      'Credit Limit': 'سقف اعتبار',
      'Account Title': 'عنوان حساب',
      'Account Registration': 'افتتاح حساب',
      'Create Customer': 'ایجاد مشتری',
      'Site under construction or maintenance': 'سایت در حال ساخت است ...',
      'building not finished yet': 'محتوای این قسمت هنوز آماده نیست :)',
      And: 'و',
      English: 'انگلیسی',
      Persian: 'دری',
      Title: 'عنوان',
      Account: 'حساب',
      Drafts: 'حواله ها',
      Draft: 'حواله',
      Equal: 'معادل',
      Documents: 'مدارک',
      Reports: 'گزارشات',
      Settings: 'تنظیمات',
      Administrator: 'مدیر سیستم',
      Dashboard: 'داشبورد',
      Welcome: 'خوش آمدید',
      Salary: 'دستمزد',
      'Moneyx Accounting Panel': 'صفحه حسابداری مانیکس',
      'Moneyx Exchange Accounting Software': 'نرم افزار حسابداری مانیکس ',
      'First Name': 'نام',
      'Middle Name': 'لقب',
      'Last Name': 'تخلص',
      Gender: 'جنسیت',
      Undefined: 'تعریف ناشده',
      Unknown: 'نامعلوم',
      Male: 'مرد',
      Female: 'زن',
      'Phone Number': 'شماره تماس',
      'Language Selection': 'انتخاب زبان',
      'Transfer Owners': 'حواله داران', // comment required
      Description: 'توضیحات',
      Return: 'بازگشت',
      'Register New Customer': 'ثبت مشتری جدید',
      'Basic Information': 'اطلاعات اولیه',
      Colon: ':',
      'Account Number': 'شماره حساب',
      'Add Account': 'افزودن حساب',
      'Occupation And Personal Details': 'اطلاعات فردی و شغلی',
      'Add Address': 'افزودن آدرس',
      'Bank Information': 'اطلاعات بانک',
      Attachment: 'ضمیمه',
      'Birth Place Country': 'محل تولد (کشور)',
      'Live Place Country': 'کشور محل زندگی',
      Nationality: 'ملیت',
      'Birth Place': 'محل تولد',
      'Birth Date': 'تاریخ تولد',
      Email: 'ایمیل',
      Website: 'وب سایت',
      Occupation: 'شغل',
      'Other Occupation': 'شغل دیگر',
      'Occupation Branch': 'شعبه شغلی',
      'Economic Registering Number': 'کد اقتصادی',
      Continue: 'ادامه',
      England: 'انگلیس',
      Iranian: 'ایرانی',
      Programmer: 'برنامه نویس',
      Employee: 'کارمند',
      Country: 'کشور',
      Province: 'ولایت',
      City: 'شهر',
      'Zip Code': 'کد پستی',
      'Exact Address': 'آدرس دقیق',
      Tehran: 'تهران',
      London: 'لندن',
      'Currency Type': 'نوع ارز',
      'Account Owner Name': 'نام صاحب حساب',
      'Branch Code': 'کد شعبه',
      'Swift Code': 'کد سوئیفت',
      IBAN: 'شماره شبا',
      Register: 'ثبت',
      Rial: 'ریال',
      Pond: 'پوند',
      CEO: 'مدیرعامل',
      'Document Type': 'نوع مدرک',
      'Document Number': 'شماره مدرک',
      'Expire Date': 'تاریخ انقضا',
      'Issue Date': 'تاریخ صدور',
      Profile: 'پروفایل',
      'Sign Out': 'خروج',
      'Document Status': 'وضعیت مدرک',
      Permanent: 'دائمی',
      Current: 'فعلی',
      'Type 1': 'نوع اول',
      'Type 2': 'نوع دوم',
      'Drop Your Desired File Here': 'فایل مورد نظر را اینجا رها کنید',
      Or: 'یا',
      Choose: 'انتخاب کنید',
      'Valid Formats': 'فرمت‌های مجاز',
      Application: 'برنامه',
      'Application Settings': 'تنظیمات برنامه',
      'Edit Number': 'ویرایش شماره',
      'Connected Account': 'حساب متصل',
      'Block Access To Application': 'عدم دسترسی به برنامه',
      'Send Notification Via': 'ارسال اطلاعیه از طریق',
      SMS: 'پیامک',
      Telegram: 'تلگرام',
      Whatsapp: 'واتساپ',
      'Accept And Register': 'تایید و ثبت',
      Customers: 'مشتریان',
      'Customers List': 'فهرست مشتریان',
      'Draft Orders List': 'فهرست سفارشات حواله',
      Customer: 'مشتری',
      List: 'فهرست',
      'Register Customer': 'ثبت مشتری',
      'Select Customer': 'انتخاب مشتری',
      'Sign in to your account to continue':
        'برای ادامه لطفا وارد حساب تان شوید',
      'Welcome back': 'خوش آمدید',
      None: 'هیچکدام',
      'Email Address': 'ایمیل آدرس',
      Password: 'رمز عبور',
      'Sign in': 'ورود',
      'Remember me': 'مرا به خاطر بسپار',
      'Forgot password': 'رمز عبور تان را فراموش کرده اید!',
      Use: 'استفاده کنید',
      and: 'و',
      'to sign in': 'برای ورود.',
      Moneyx: 'مانیکس',
      'Exchange Accounting Software': 'نرم افزار حسابداری صرافی',
      Balance: 'بیلانس',
      'Sample Balance': '5/435/000',
      'Sample Balance Currency': 'افغانی',
      'Complete Customer Information': 'تکمیل اطلاعات مشتری',
      'Next Step': 'مرحله بعدی',
      Sent: 'ارسال',
      'Not Sent': 'ارسال نشده',
      'Customer Information': 'اطلاعات مشتری',
      All: 'همه',
      Currencies: 'ارزها',
      'Draft Submission': 'ثبت حواله',
      Branch: 'نمایندگی',
      Sender: 'فرستنده',
      Receiver: 'گیرنده',
      'Draft Currency Type': 'نوع ارز حواله',
      'Company Registration': 'ثبت شرکت',
      'Contact Details': 'اطلاعات تماس',
      Address: 'آدرس',
      Addresses: 'آدرس‌ها',
      Shareholders: 'سهام‌داران',
      'Add/Update Shareholders': 'افزودن / ویرایش سهام‌دار',
      'Receivers List': 'فهرست گیرنده‌ها',
      'Receiver Registration': 'ثبت گیرنده',
      Accept: 'تایید',
      'Customer Registration': 'ثبت مشتری',
      'Search By': 'جستجو با',
      Name: 'نام',
      Comma: '،',
      'Document Prime': 'مدرک',
      'Advanced Search': 'جستجوی پیشرفته',
      'Customer Number': 'شماره مشتری',
      'Customer Name': 'نام مشتری',
      Group: 'گروه',
      Groups: 'گروه‌ها',
      'Father Name': 'نام پدر',
      'Account History': 'تاریخچه حساب',
      'Applications Settings': 'تنظیمات برنامه‌ها',
      'Draft Receivers': 'گیرنده‌های حواله',
      'Send Message': 'ارسال پیام',
      'Customer Full Details': 'اطلاعات کامل مشتری',
      'Download Customers List Summary': 'دانلود فهرست با خلاصه مشتریان',
      'Download Customers Full List': 'دانلود فهرست با مشخصات کامل',
      'Document Expiry Date': 'تاریخ انقضاء مدرک',
      To: 'تا',
      Select: 'انتخاب',
      Collateral: 'معرف',
      'Communication Way': 'راه ارتباطی',
      Enable: 'فعالسازی',
      Webcam: 'کمره',
      Upload: 'آپلود',
      Photo: 'عکس',
      'Add New Phone Number': 'افزودن شماره جدید',
      'At Least One Number Is Mandatory': 'حداقل یک شماره اجباری است',
      'Phone Number Cannot Be Repeated.': 'شماره تلفن نمی توانید تکراری باشد.',
      'Register Customer Without Additional Information':
        'ثبت شخص بدون اطلاعات تکمیلی',
      'Wholesale Exchange': 'حواله‌دار عمده',
      'Retail Exchange': 'حواله‌دار پرچون',
      Create: 'ایجاد',
      'System Management': 'مدیریت سیستم',
      Business: 'تجارت',
      'Create Group': 'ایجاد گروه',
      'System Settings': 'تنظیمات سیستم',
      'Advanced Settings': 'تنظیمات پیشرفته',
      'Initial Settings': 'تنظیمات اولیه',
      'Header Settings': 'تنظیمات سر ورقی',
      'Add Currency': 'افزودن ارز',
      'Update Currency': 'ویرایش ارز',
      'Upload a logo for the header': 'آپلود لوگو برای سر ورقی',
      Debtor: 'باقی‌دار',
      Creditor: 'طلبکار',
      'Create Currency': 'ایجاد ارز',
      Normal: 'عادی',
      Other: 'دیگر',
      Dollar: 'دالر',
      'Account Remaining': 'بیلانس حساب',
      Remaining: 'بیلانس',
      From: 'از',
      Date: 'تاریخ',
      'Balance Currency Type': 'نوع ارز موجودی',
      'Mobile Number': 'شماره موبایل',
      'Account Details': 'اطلاعات حساب',
      'Banks List': 'فهرست بانک‌ها',
      'Banks Management': 'مدیریت بانک‌ها',
      Row: 'ردیف',
      Add: 'افزودن',
      New: 'جدید',
      Old: 'قدیم',
      'New Bank': 'بانک جدید',
      'Bank Name': 'نام بانک',
      'Card Number': 'شماره کارت',
      'Bank Branch Code': 'کد شعبه',
      Status: 'وضعیت',
      Active: 'فعال',
      Archived: 'آرشیف',
      'Bank Book': 'دفتر بانک',
      'No Bank Is Registered': 'هیچ بانکی ثبت نشده است',
      'No Submited Transaction': 'معامله‌ای ثبت نشده است',
      Bank: 'بانک',
      Edit: 'ویرایش',
      IncreaseCredit: 'افزایش اعتبار',
      CancelDraft: 'لغو حواله',
      'Edit Draft': 'ویرایش حواله',
      'Edit Transaction': 'ویرایش معامله',
      'Edit Bank Transaction': 'ویرایش معامله بانکی',
      'Submit Edit': 'ثبت ویرایش',
      'Archiving The Account': 'آرشیف کردن حساب',
      'Cash withdrawal': 'پرداخت نقدی',
      'Cash deposit': 'دریافت نقدی',
      Deposit: 'دریافت',
      Withdraw: 'پرداخت',
      'Search In Moneyx': 'جستجو در مانیکس',
      'Quick Access': 'دسترسی سریع',
      'Print Information': 'چاپ اطلاعات',
      'Upload File': 'آپلود فایل',
      'Transaction Description': 'شرح معامله',
      'Receipt Number': 'شماره فیش',
      'Transaction Amount': 'مبلغ معامله',
      Messages: 'پیام‌ها',
      Notifications: 'اعلان‌ها',
      Languages: 'زبان‌ها',
      'Account Balance': 'بیلانس حساب',
      'Cash Desk': 'خزانه',
      'System Accounts': 'حساب‌های سیستم',
      Cash: 'خزانه',
      Costs: 'مصارفات',
      Commission: 'کمیشن',
      creditaccount: 'حساب اعتبارات',
      UnknownCreditAccount: 'اعتبار نامعلوم',
      unknowncreditaccount: 'اعتبار نامعلوم',
      'Unexecuted draft account': 'حواله‌های اجرا نشده',
      'unexecuted draft account': 'حواله‌های اجرا نشده',
      'Account Information': 'اطلاعات حساب',
      Accounts: 'حساب‌ها',
      ChargingSale: 'فروش شارژ',
      UnrealizedDraft: 'حواله‌های اجرا نشده',
      'Unrealized Drafts': 'حواله‌های اجرا نشده',
      UnexecutedDraft: 'حواله‌های اجرا نشده',
      BankPayment: 'دریافت و پرداخت بانکی',
      'No Option Message': 'موردی وجود ندارد',
      'Branch Registration': 'ثبت نمایندگی',
      'Branch Edition': 'ویرایش نمایندگی',
      'Advanced Details': 'اطلاعات پیشرفته',
      'Drafts List': 'فهرست حواله‌ها',
      'Draft Documents List': 'فهرست مدارک حواله',
      'Unrealized Drafts List': 'فهرست حواله‌های اجرا نشده',
      'Pending Drafts List': 'فهرست حواله های در انتظار',
      'Branches List': 'فهرست نمایندگی‌ها',
      'Branch List': 'فهرست نمایندگی‌',
      'Trade Currency': 'تبادله اسعار',
      'Currencies Management': 'مدیریت ارزها',
      'Branch Account': 'حساب نمایندگی',
      'Credits Account': 'حساب اعتبارات',
      'Customer Credit Account': 'حساب اعتبار مشتری',
      'Unknown Bank Deposits': 'جمع های نامعلوم بانکی',
      'Submit Cash Transaction': 'ثبت معامله نقدی',
      'Submit Bank Transaction': 'ثبت معامله بانکی',
      'New Submit Bank Transaction': 'ثبت معامله بانکی (جدید)',
      'Register Person': 'ثبت شخص',
      'Edit Person': 'ویرایش شخص',
      'Edit Company': 'ویرایش شرکت',
      CommonAccount: 'حساب',
      BranchAccount: 'نمایندگی',
      BankAccount: 'بانک',
      'Unknown Bank Transactions': 'معاملات نامعلوم بانکی',
      customer: 'مشتری',
      receiver: 'گیرنده',
      'Interbank Transaction': 'معامله بین بانکی',
      // 'Outgoing Draft Submission': 'ثبت حواله صادره',
      // 'Incoming Draft Submission': 'ثبت حواله وارده',
      'Miscellaneous Draft Submission': 'ثبت حواله متفرقه',
      'Draft Conduction': 'اجرای حواله',
      'Cheques List': 'فهرست چک‌ها',
      'Debtor And Creditor List': 'فهرست باقی‌داران و طلبکاران',
      'Commissions Report': 'گزارش کمیشن ها',
      'Trade Currencies List': 'فهرست تبادله اسعار',
      SavedToCashDeskOrBankAccount: 'ذخیره شده',
      SavedToCustomerAccount: 'واگذار شده',
      HasReturned: 'برگشت خورده',
      CashedToMoney: 'پاس شده',
      'Cashed to money': 'پاس شده',
      'cashed to money': 'پاس شده',
      UnknownBankAccounts: 'معاملات نامعلوم بانکی',
      'Profit And Loss Statement': 'بیلانس مفاد و ضرر',
      'Payment Order': 'دستور پرداخت',
      profile: 'پروفایل',
      'Operation of the day': 'عملیات روز',
      DocumentIsPrivate: 'سند بدون ثبت توضیحات است',
      'Net Profit': 'خالص مفاد',
      'Gross Profit': 'مفاد ناخالص',
      Cost: 'مصرف',
      Delete: 'حذف',
      'no attachments': 'ضمیمه ندارد',
      'The Entered Amount Is Bigger Than The Sum Of The Payment Order':
        'مبلغ وارد شده، از جمع مقدار مبالغ فیش بالاتر می باشد',
      OK: 'تایید',
      Cancel: 'لغو',
      Clear: 'پاک کردن',
      'System Guides': 'درباره سیستم',
      'System Main Currency': 'ارز پیش فرض سیستم',
      'Increase Customer Credit': 'افزایش اعتبار مشتری',
      'Date And Time': 'تاریخ و زمان',
      'Credit Currency': 'ارز اعتبار',
      'Credit Amount': 'مبلغ اعتبار',
      'Transaction Information': 'اطلاعات معامله',
      'Credit Registration': 'ثبت اعتبار',
      'Reported Bugs': 'خطاهای گزارش شده',
      'Excel Data Output': 'خروجی اکسل اطلاعات',
      'Bug Details': 'جزئیات خطا',
      'My Tickets': 'تیکت‌های من',
      'From Date': 'از تاریخ',
      'To Date': 'تا تاریخ',
      Failed: 'ناموفق',
      Pend: 'در انتظار',
      Success: 'تایید شده',
      'Request Status': 'وضعیت درخواست',
      'Pending Transactions List': 'فهرست معامله های در انتظار',
      'Submitted By Employee': 'ثبت شده توسط کارمند',
      'Submitted By Customer': 'ثبت شده توسط مشتری',
      'Submitted By Mirror Branch': 'ثبت شده توسط نمایندگی مقابل',
      'Remove Filters': 'حذف فیلترها',
      'From Number': 'از شماره',
      'To Number': 'تا شماره',
      Submit: 'تایید',
      Reject: 'رد',
      'Error Number': 'شماره خطا',
      'Error Title': 'عنوان خطا',
      'Error Opening Date': 'تاریخ باز شدن خطا',
      'Error Closing Date': 'تاریخ بسته شدن خطا',
      'Error Status': 'وضعیت خطا',
      Operation: 'عملیات',
      'Operation Type': 'نوع عملیات',
      Number: 'شماره',
      Detail: 'جزئیات',
      'Date Of Payment Order': 'تاریخ دستور پرداخت',
      'Account Owner': 'صاحب حساب',
      'Total Deposit Amount': 'جمع مبلغ واریز',
      'Birth Certificate': 'کارت تولد',
      'National Card': 'کارت ملی',
      Passport: 'پاسپورت',
      'Physical Tezkire': 'تذکره کاغذی',
      'Electronic Tezkire': 'تذکره برقی',
      'Kimlik Card': 'کارت کیملیک',
      'Driving License': 'جواز رانندگی',
      'Temporary Residence Card Of Foreign Nationals': 'کارت اقامه',
      Police: 'سرباز',
      'Civil Registry Office': 'اداره ثبت احوال',
      'Immigration Office': 'اداره مهاجرت',
      'Live Currency Rate': 'نرخ لحظه ای ارز',
      'Due to the long number of letters of the selected document information, it is not possible to share.':
        'نظر به طولانی بودن تعداد حروف اطلاعات سند انتخابی، امکان اشتراک گذاری فراهم نیست.',
      'Creditor Customer': 'مشتری طلبکار',
      Recieve: 'دریافت',
      'Debtor Customer': 'مشتری باقی دار',
      'Bank Transaction Is Confidential': 'معامله بانکی بدون ثبت توضیحات باشد',
      'Transaction Currency': 'ارز معامله',
      'Commission Amount': 'مبلغ کمیشن',
      'Commission Currency': 'ارز کمیشن',
      'Transaction Date': 'تاریخ معامله',
      'Is There A Fee For This Transaction': 'این معامله کمیشن دارد؟',
      // Incoming: 'وارده',
      // Outgoing: 'صادره',
      'Buyer Commission': 'کمیشن خریدار',
      'Seller Commission': 'کمیشن فروشنده',
      'Transaction Type': 'نوع معامله',
      Documentations: 'مستندات',
      System: 'سیستم',
      'System Account': 'حساب سیستم',
      'Customer Commission': 'کمیشن مشتری',
      'Branch Commission': 'کمیشن نمایندگی',
      'Transaction Number': 'شماره معامله',
      'Transaction Status': 'وضعیت معامله',
      'Settings And File Upload': 'تنظیمات و آپلود فایل',
      'Other Number': 'شماره متفرقه',
      'Debtor Commission': 'کمیشن باقی دار',
      'Creditor Commission': 'کمیشن طلبکار',
      'Debtor Account': 'حساب باقی دار',
      'Creditor Account': 'حساب طلبکار',
      'Edit Miscellaneous Draft': 'ویرایش حواله متفرقه',
      'Spot Rate': 'نرخ لحظه‌ای',
      'Source Of Money': 'منبع پول',
      'Purpose Of Exchange': 'هدف معامله',
      'Organization Chart': 'چارت تشکیلاتی',
      // ************************ end default document types **************************
      // ************************ phrase items translations **************************
      'transfer between accounts': 'انتقال بین حساب',
      'transaction id': 'شماره معامله',
      'transaction id:': 'شماره معامله:',
      'transfer between accounts with': 'انتقال بین حساب با',
      amount: 'مبلغ',
      currency: 'ارز',
      debtor: 'باقی‌دار',
      creditor: 'طلبکار',
      Percent: 'درصد',
      Liquidity: 'نقدینگی',
      'Draft Number': 'شماره حواله',
      // 'outgoing draft order': 'حواله صادره',
      // 'incoming draft order': 'حواله وارده',
      'Payment Place': 'محل پرداخت',
      to: 'به',
      from: 'از',
      at: 'در',
      'was registered.': 'ثبت شد.',
      'commission for': 'کمیشن',
      'bank account': 'حساب بانک',
      'customer account': 'حساب مشتری',
      'sell currency': 'فروش ارز',
      'buy currency': 'خرید ارز',
      'bank transaction': 'معامله بانکی',
      commission: 'کمیشن',
      'cash transaction': 'معامله نقدی',
      unrealizeddraft: 'حواله‌های اجرا نشده',
      unexecuteddraft: 'حواله‌های اجرا نشده',
      unknownbankaccounts: 'معاملات نامعلوم بانکی',
      chargingsale: 'فروش شارژ',
      'buy charge': 'خرید شارژ',
      cash: 'خزانه',
      costs: 'مصارف',
      'New Version Available Phrase':
        'نسخه جدید سیستم در دسترس است ؛ لطفا صفحه را بروزرسانی کنید',
      // ************************ end phrase items translations **************************
      // ***********************************
      // countries
      // ***********************************
      Afghanistan: 'افغانستان',
      Australia: 'استرالیا',
      China: 'چین',
      Denmark: 'دانمارک',
      Germany: 'آلمان',
      Greece: 'یونان',
      Iran: 'ایران',
      Iraq: 'عراق',
      Norway: 'ناروی',
      Pakistan: 'پاکستان',
      'Saudi Arabia': 'عربستان سعودی',
      Sweden: 'سوئدن',
      Turkey: 'ترکیه',
      'United Arab Emirates': 'امارات متحده عربی',
      Baghdad: 'بغداد',
      Ankara: 'انقره',
      Kabul: 'کابل',
      'Islamabad, Karachi': 'کراچی ,اسلام آباد',
      // ***********************************
      // end countries
      // ***********************************
      // ***********************************
      // actionTypes
      // ***********************************
      TransferBetweenTwoAccounts: 'انتقال بین حساب',
      Cheque: 'چک',
      CashTransaction: 'معامله نقدی',
      // IncomingDraft: 'حواله وارده',
      // OutgoingDraft: 'حواله صادره',
      Miscellaneous: 'متفرقه',
      BuySellCurrency: 'تبادله اسعار',
      BankTransaction: 'معامله بانکی',
      RemoveCheckout: 'حذف تسویه',
      PendDraft: 'حواله در انتظار',
      RemovePendDraft: 'حذف حواله در انتظار',
      ExecuteDraft: 'اجرای حواله',
      InterBankTransaction: 'معامله بین بانکی',
      Checkout: 'تسویه',
      PaymentOrderReceipt: 'رسید دستور پرداخت',
      ChangeBranch: 'تبدیل نرخ',
      UnknownBankTransaction: 'معامله نامعلوم بانکی',
      AddAccount: 'افزودن حساب',
      UpdateAccount: 'ویرایش حساب',
      DeleteAccount: 'حذف حساب',
      AddBank: 'افزودن بانک',
      UpdateBank: 'ویرایش بانک',
      DeleteBank: 'حذف بانک',
      AddCustomer: 'افزودن مشتری',
      UpdateCustomer: 'ویرایش مشتری',
      DeleteCustomer: 'حذف مشتری',
      AddCompany: 'افزودن شرکت',
      UpdateCompany: 'ویرایش شرکت',
      DeleteCompany: 'حذف شرکت',
      AddBranch: 'افزودن نمایندگی',
      UpdateBranch: 'ویرایش نمایندگی',
      DeleteBranch: 'حذف نمایندگی',
      ConnectToBranch: 'اتصال به نمایندگی',
      OneWayConnecting: 'اتصال یک طرفه',
      BranchName: 'نام نمایندگی',
      BranchManager: 'مسئول نمایندگی',
      BranchIsNotFound: 'نمایندگی یافت نشد',
      SelecTheDesiredBranch: 'نمایندگی مورد نظر را انتخاب کنید',
      InOneWayConnectionModeTheCounterpartyIsNotAblleToSeeYoureTransactions:
        'در حالت اتصال یک طرفه نمایندگی مقابل قادر به مشاهده معاملات شما نیست',
      AddCurrency: 'افزودن ارز',
      UpdateCurrency: 'ویرایش ارز',
      DeleteCurrency: 'حذف ارز',
      AddPayOrder: 'افزودن دستور پرداخت',
      UpdatePayOrder: 'ویرایش دستور پرداخت',
      DeletePayOrder: 'حذف دستور پرداخت',
      PublishTransactionInWhatsapp: 'ارسال معامله در واتساپ',
      ShouldHaveSamevaluein: 'باید دارای مقادیر یکسان باشند در',
      'In progress': 'در حال پردازش',
      PoliticalFighter: 'متبارز سیاسی',
      HighRisk: 'پرخطر',
      ModerateRisk: 'خطر متوسط',
      LowRisk: 'کم‌خطر',
      SmallBusiness: 'کسب و کار کوچک',
      BigBusiness: 'کسب و کار بزرگ',
      CurrencyAuction: 'لیلام اسعار',
      SanctionList: 'تطبیق لیست تعزیرات',
      'Central analysis of trades and financial reports':
        'مرکز تحلیل معاملات و راپورهای مالی',
      'Exchange license validation': 'اعتبارسنجی جواز',
      Accesses: 'دسترسی‌ها',
      TransactionDate: 'تاریخ معامله',
      Transactions: 'معامله‌ها',
      Position: 'موقعیت شغلی',
      UserAccountSystem: 'سیستم حساب کاربری',
      AccountingSystem: 'سیستم حسابداری',
      'Departments List': 'لیست شعب',
      'Add New Departments': 'افزودن شعبه جدید',
      'Access To Drafts': 'دسترسی به حواله',
      'Access To System Settings': 'دسترسی به تنظیمات سیستم',
      'No Content Message': 'موردی وجود ندارد',
      ExitFromSystem: 'خروج از برنامه'
      // ***********************************
      // end actionTypes
      // ***********************************
    },
    dir: 'rtl'
  }
};

export default resources;
